import React, { useEffect, useState, useRef } from 'react';
import { Container, Card, CardDeck } from 'reactstrap';
import QR from './images/qr-code.png'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './images/imac-dollhouse.png'
import dhb from './images/dhb-logo.png'
import dhb_promo from './images/dollhouse-promo.png'
import imac_promo from './images/imac-promo.png'
import imac from './images/imacms-logo.png'
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Input, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import { BsInstagram, BsFacebook, BsTiktok, BsYelp } from 'react-icons/bs'
import { ColorRing } from 'react-loader-spinner'
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const CountdownTimer = () => {
  const [time, setTime] = useState(5 * 60); // 5 minutes in seconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = String(Math.floor(time / 60)).padStart(2, '0');
  const seconds = String(time % 60).padStart(2, '0');

  return (
    <small className="font-weight-bold">{`Time left to get our exclusive deal: ${minutes}:${seconds}`}</small>
  );
};

const items = [
  { id: 1, question: 'What is your name?', type: 'text' },
  { id: 2, question: 'How old are you?', type: 'number' },
  { id: 3, question: 'Select your gender', type: 'select' },
  { id: 4, question: 'Any comments?', type: 'textarea' },
  { id: 5, question: 'Accept terms?', type: 'checkbox' }
];

const services = [
  'Teeth Whitening',
  'Hair',
  'Laser Hair Removal',
  'Lash Extensions',
  'Brow Micro-Shading & Lamination',
  'Body Work + Lymphatic Massage',
  'Semaglutide',
  'Botox',
  'Morpheus8',
  'Dermal Filler',
  'Hydrafacial',
  'IV Therapy',
  'Vitamin Shots'
];

function App() {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [errors, setErrors] = useState({ name: '', phoneNumber: '', email: '' });
  const [isNextClicked, setNextClicked] = useState(false);
  const [successPage, setSuccessPage] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);


  useEffect(() => {


  }, []);

  if (!isMobile()) {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff4ee',
      }} className="p-4">

        <p style={{
          fontFamily: 'The Seasons',
          color: '#cde',
          fontSize: '24px',
        }}>
          Visit this QR code on your phone to get the exclusive deal<span style={{ fontFamily: 'Arial' }}>!</span>
        </p>
        {/* <img src={QR} alt="QR Code" style={{ width: '50%' }} /> */}
      </div>

    );
  }
  const handleSubmit = () => {
    setSubmitLoading(true);
    // Log the form data to the console
    console.log('Name:', name);
    console.log('Phone Number:', phoneNumber);
    console.log('Email:', email);
    console.log('Selected Interests:', selectedServices);


    // Prepare the data to be sent in the POST request
    const formData = {
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      selectedServices: selectedServices,
    };

    // Send the POST request to the endpoint using axios
    axios.post('https://hook.us1.make.com/5kjcf8addg6ey6kkyohoxhb32w1odqp9', formData)
      .then(response => {
        console.log('Success:', response.data);
        setSubmitLoading(false);
        setNextClicked(false);
        setSuccessPage(true);
      })
      .catch(error => {
        setSubmitLoading(false);
        console.error('Error:', error);
      });
  };

  const validateAndGoNext = () => {
    let newErrors = { name: '', phoneNumber: '', email: '' };
    let isValid = true;

    if (name.trim() === '') {
      newErrors.name = '*Required';
      isValid = false;
    }

    if (phoneNumber.trim() === '') {
      newErrors.phoneNumber = '*Required';
      isValid = false;
    }

    if (email.trim() === '' || !email.includes('@')) {
      newErrors.email = '*Required';
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      setNextClicked(true);
    }
  };

  const toggleService = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter(s => s !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };
  if (successPage) {
    return (
      <>
        <div className="p-2 text-center" style={{ background: 'linear-gradient(to right, #f953c6, #b91d73)' }}>
          <br />
          <br />
          <h2 className="text-white">🎉 Congratulations 🎉</h2>
          <p className="text-white font-weight-bold"> You have 2 exclusive deals waiting for you!</p>
          <br />
          <br />
        </div>
        <br />
        <div className="p-2 text-center">
          <Card className="rounded-card">
            <Row className="align-items-center">
              <Col>
                <a href="https://www.vagaro.com/thedollhousebeauty" target="_blank">
                  <img className="mr-auto" src={dhb} ></img>
                </a>
              </Col>
              <Col className="text-left">
                <span className="font-weight-bold">Dollhouse Beauty</span>
                <br />
                <a href="https://maps.app.goo.gl/HGqV2UGaRVVG8fhM9" target="_blank"><small>4521 Van Nuys Blvd Unit A<br />
                  Sherman Oaks, CA 91403 </small></a>
                <br />
                <div className="p-3" style={{ justifyContent: 'space-between', display: 'flex', fontSize: '1.5rem', color: '#59A3A8' }}>
                  <a style={{ color: '#59A3A8' }} href="https://www.facebook.com/TheDHB" target="_blank"><BsFacebook /></a>
                  <a style={{ color: '#59A3A8' }} href="https://www.instagram.com/thedollhousebeauty/" target="_blank"><BsInstagram /></a>
                  <a style={{ color: '#59A3A8' }} href="https://www.yelp.com/biz/the-dollhouse-beauty-sherman-oaks-2" target="_blank"><BsYelp /></a>
                </div>
              </Col>
            </Row>
            <a href="https://www.vagaro.com/thedollhousebeauty/book-now" target="_blank" style={{ textDecoration: 'none' }}>
              <Card style={{ border: '2px solid #59a3a8', borderRadius: '15px', backgroundColor: "#cbf0f2" }}>
                <img src={dhb_promo}></img>
              </Card>
            </a>
          </Card>
          <br />
          <Card className="rounded-card">
            <Row className="align-items-center">
              <Col className="text-right">
                <span className="font-weight-bold">IMAC Med Spa</span>
                <br />
                <a href="https://maps.app.goo.gl/VHUZBiPcZyYDhn846" target="_blank"><small>24237 Main St<br />
                  Santa Clarita, CA 91321 </small></a>
                <br />
                <div className="p-3" style={{ justifyContent: 'space-between', display: 'flex', fontSize: '1.5rem', color: '#F7947C' }}>
                  <a style={{ color: '#F7947C' }} href="https://www.yelp.com/biz/imac-med-spa-newhall" target="_blank"><BsYelp /></a>
                  <a style={{ color: '#F7947C' }} href="https://www.instagram.com/imacmedspa/?hl=en" target="_blank"><BsInstagram /></a>
                  <a style={{ color: '#F7947C' }} href="https://www.tiktok.com/@imacmedspa_" target="_blank"><BsTiktok /></a>
                </div>
              </Col>
              <Col>
                <a href="https://www.imacmedspa.com/" target="_blank">
                  <img className="ml-auto" src={imac} ></img>
                </a>
              </Col>
            </Row>
            <a href="tel:+1234567890" style={{ textDecoration: 'none' }}>
              <Card style={{ border: '2px solid #f7947c', borderRadius: '15px', backgroundColor: "#ffeae5" }}>
                <img src={imac_promo}></img>
              </Card>
            </a>
          </Card>
          <br />
          <br />

        </div>

      </>
    )
  } else {
    return (
      <>
        <div style={{ backgroundColor: '#ff603d', color: 'white', position: 'sticky', top: 0, zIndex: '9999' }} className="text-center">
          <  CountdownTimer />
        </div>
        <div style={{ backgroundColor: '#fff4ee', display: 'flex', flexDirection: 'column', height: '100vh' }}>


          <div>
            <img style={{ width: '100%' }} src={logo}></img>
          </div>
          <div className="px-3 pb-3">
            <Card className="rounded-card text-center">
              <span className="font-weight-bold">🎉  You're invited!  🎉</span>
              <br />
              <small>Dollhouse Beauty & IMAC Med Spa are collaborating to bring you <span className="font-weight-bold">exclusive discounts and offers</span> that you can only get through this page!</small>
            </Card>
          </div>

          <div className="p-4 shadow text-left"
            style={{
              backgroundColor: '#fcf7f4',
              borderRadius: '25px 25px 0 0',
              border: '1px solid black',
              flexGrow: 1
            }}>
            {
              isNextClicked ?
                <>
                  <div className="text-left font-weight-bold text-black">
                    Step 2: Tell us what you like
                  </div>
                </> :
                <>
                  <div className="text-left font-weight-bold text-black">
                    Step 1: Fill out the form below
                  </div>
                </>
            }
            <br />
            <FormGroup>
              {isNextClicked ? (
                <>
                  <Label className="mt-3 text-black">What are you interested in?</Label>
                  <div>
                    {Array.from({ length: Math.ceil(services.length / 2) }, (_, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        <Row>
                          {Array.from({ length: 2 }, (_, colIndex) => {
                            const index = rowIndex * 2 + colIndex;
                            const service = services[index];
                            if (service === undefined) return null;

                            const isSelected = selectedServices.includes(service);
                            return (
                              <Col key={service}>
                                <Card
                                  onClick={() => toggleService(service)}
                                  className={`text-center ${isSelected ? 'selected' : 'rounded-card'}`}
                                >
                                  <small className="text-black">{service}</small>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                  <div>
                    <br />
                    {
                      submitLoading ?
                        <>
                          <Button className="rounded-card" style={{ color: 'white', width: '100%', background: 'linear-gradient(to right, #f953c6, #b91d73)' }}>
                            <ColorRing
                              visible={true}
                              // height="80"
                              // width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white']}
                            />
                          </Button>
                        </> :
                        <>
                          <Button className="rounded-card" onClick={handleSubmit} style={{ color: 'white', width: '100%', background: 'linear-gradient(to right, #f953c6, #b91d73)' }}>
                            Submit
                          </Button>
                        </>
                    }
                    {/* <Button className="rounded-card" onClick={handleSubmit} style={{ color: 'white', width: '100%', background: 'linear-gradient(to right, #f953c6, #b91d73)' }}>
                    Submit
                  </Button> */}
                    <br />
                    <br />
                    <small onClick={() => { setNextClicked(false) }}>Back</small>
                  </div>
                </>
              ) : (
                <>
                  <Label className="text-black" for="name">Name <small className="text-danger">{errors.name}</small></Label>
                  <Input className="text-black"
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    onChange={(e) => setName(e.target.value)}
                    style={{ border: errors.name.length > 1 ? '1px solid red' : '1px solid #ccc' }} />


                  <Label for="phoneNumber" className="mt-3 text-black">Phone Number <small className="text-danger">{errors.phoneNumber}</small> </Label>
                  <Input className="text-black"
                    type="tel"
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={{ border: errors.phoneNumber.length > 1 ? '1px solid red' : '1px solid #ccc' }} />


                  <Label for="emailAddress" className="mt-3 text-black">Email Address <small className="text-danger">{errors.email}</small></Label>
                  <Input className="text-black"
                    type="email"
                    id="emailAddress"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ border: errors.email.length > 1 ? '1px solid red' : '1px solid #ccc' }} />


                  <div>
                    <br />
                    <Button className="rounded-card" onClick={validateAndGoNext} style={{ color: 'white', width: '100%', background: 'linear-gradient(to right, #f953c6, #b91d73)' }}>
                      Next
                    </Button>
                  </div>
                </>
              )}
            </FormGroup>
          </div>
        </div>
      </>

    );
  }
}

export default App;